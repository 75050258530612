<template>
  <div class="cal-wrap">
    <date-picker
      v-model="value"
      range
      :editable="false"
      format="DD-MM-YYYY"
      value-type="YYYY-MM-DD"
      type="date"
      input-class="mx-input input-date"
      popup-class="popup-date"
      placeholder="Select date range"
      :disabled-date="notAfterToday"
      @change="change"
    />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css" 

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      value: []
    }
  },
  methods: {
    change() {
      this.$emit("changed-date", this.value)
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0))
    },
    // disabledRange: function (date) {
    //   return date < new Date(2020, 10, 2) || date > new Date(new Date().setHours(0, 0, 0, 0))
    // }
  }
}
</script>

<style>
.mx-input.input-date {
  background-color: #424242;
  color: #fff;
}

.cal-wrap .mx-icon-calendar,
.cal-wrap .mx-icon-clear {
  color: #fff;
}

.input-date::placeholder {
  color: #a3a3a3;
}

.popup-date.mx-datepicker-main {
  color: #fff;
  background-color: #424242;
  border: 1px solid #424242;
}

.popup-date .mx-calendar-content .cell.active {
  color: #fff;
  background-color: #7a6fdb;
}

.mx-table-date .today {
  color: #7a6fdb;
}

.mx-btn {
  color: #fff;
}
.mx-calendar-content .cell.disabled {
  cursor: not-allowed;
  color: grey;
  background-color: transparent;
}
</style>
