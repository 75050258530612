import api from "./index"

/**
 * Get all Advertising
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getAllTransactionRequest = (params, includes = []) => {
  return api
    .request("admin/batch-transaction-list")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Find Transaction by id
 *
 * @param params
 * @return {*}
 */
export const findUserTransactionIdRequest = params => {
  return api
    .request("admin/batch-transaction")
    .withParams(params)
    .get()
}

/**
 * Create Advertising
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const repeatPaymentRequest = (params, includes = []) => {
  return api
    .request("admin/payouts/send")
    .withParams(params)
    .withIncludes(includes)
    .post()
}

/**
 * Find Transaction by id
 *
 * @param params
 * @return {*}
 */
export const transactionListRequest = params => {
  return api
    .request("admin/transaction-list")
    .withParams(params)
    .get()
}

/**
 * Find Transaction by id
 *
 * @param params
 * @return {*}
 */
export const userTransactionListRequest = params => {
  return api
    .request("admin/user-transaction-list")
    .withParams(params)
    .get()
}
